.border-active {
    border-color: #689802 !important;
}

.modal-size {
    width: 550px;
}

.progress-bar {
    background-color: #689802 !important;
}

.table-row-highlight {
    --bs-table-bg: #6898020A;
}
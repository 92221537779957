@import url('https://fonts.googleapis.com/css?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');
@import './assets/fonts/Oxanium/stylesheet.css';

@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';

$font-size-base: 0.85rem;
$min-contrast-ratio: 3;

$estorya: #689802;
$estoryalight: #dcdcdc;
$estoryamenuactive: #B6C5B9;
$estoryadark: #2C372F;
$estoryadarker: #262A32;
$muted: #5A6375;

// merge with existing $theme-colors map
$theme-colors: map-merge($theme-colors, (
  "estorya": $estorya,
  "estoryalight": $estoryalight,
  "estoryamenuactive": $estoryamenuactive,
  "estoryadark": $estoryadark,
  "estoryadarker": $estoryadarker,
  "muted": $muted,
));

@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.btn:hover {
  text-decoration: none;
}

.animated {
  animation-duration: 1s;
}
.fadeIn {
  animation-name: fadeIn;
}

.cursor-pointer {
  cursor: pointer;
}

.link-button {
  border: 0;
  background-color: transparent;
  display: inline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}

.page-nav .nav-link {
  color: $muted;
  border-bottom: 2px solid transparent;
}
.page-nav .nav-link.active {
  color: $estorya !important;
  border-bottom: 2px solid $estorya;
}

.main-nav .nav-link {}
.main-nav .nav-link:hover {
  background-color: $estoryadark;
}
.main-nav .nav-link.active {
  background-color: $estoryadarker;
}
.main-nav .nav-link img {
  filter: brightness(0) invert(1);
}
.main-nav .nav-link.active img {
  filter: unset;
}

#main {
  background-image:
    radial-gradient(77.91% 62.88% at 25.52% 79.12%, rgba(104, 152, 2, 0) 23.55%, rgba(16, 28, 16, 0.93) 86.52%);
}

#main:before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: url("assets/img/skunkworksshared_a_charming_wallpaper_featuring_a_whimsical_pil_229002d1-afc4-48c7-80b8-a7a6a706a0ed\ 1.png");
  background-size: cover;
  background-position: right;
  transform: rotate(180deg);
}

.form-check-input:checked {
  background-color: $estorya;
  border-color: $estorya;
}
.user-site-badge {
  border: solid 1px #E1735D;
  background-color: #FFE4DE;
  color: #E1735D;
}

.role-Superadmin, .role-Teacher, .role-Student {
  border: solid 1px #5B8DF5;
  background-color: #5B8DF533;
  color: #5B8DF5;
}

.user-role-badge {
  border: solid 1px #5B8DF5;
  background-color: #5B8DF533;
  color: #5B8DF5;
}
/* .role-Teacher {
  border: solid 1px #689802;
  background-color: #68980233;
  color: #689802;
}

.role-Student {
  border: solid 1px #334962;
  background-color: #33496233;
  color: #334962;
} */

/* .form-check-input:checked {
  background-color: #098E71;
  border-color: #098E71;
} */

.add-user-menu > p:hover {
  color: #689802;
}

.css-1nmdiq5-menu{
  z-index: 10;
}